import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CreateAccountForm from './CreateAccountForm';

const validationSchema = t => Yup.object({
  email: Yup.string().required(t('Enter your email'))
    .matches(/^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/, t('Invalid email format')),
  password: Yup.string().required(t('Enter your password'))
    .min(8, t('Password must have at least 8 letters, contain lowercase letters, uppercase letters and special characters'))
    .matches(/[a-z]/, t('Password must have at least 8 letters, contain lowercase letters, uppercase letters and special characters'))
    .matches(/[A-Z]/, t('Password must have at least 8 letters, contain lowercase letters, uppercase letters and special characters'))
    .matches(/[0-9]/, t('Password has to contain number'))
});

const DoctorRegistrationAccount = ({ enqueueSnackbar }) => {
  const values = {
    email: '',
    password: '',
  };
  const { t } = useTranslation();

  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');
  if (email) {
    values.email = email;
  }

  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema(t)}
    >
      {formikProps => (
        <CreateAccountForm {...formikProps} enqueueSnackbar={enqueueSnackbar} />
      )}
    </Formik>
  );
};

DoctorRegistrationAccount.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default DoctorRegistrationAccount;
